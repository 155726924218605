
// 员工基础信息
<template>
    <div class="itemInfo">
        <el-card shadow="always">
            <el-form :model="itemForm"
                     ref="itemForm"
                     :rules="itemFormRules"
                     label-width="100px"
                     label-position="left"
                     :inline="false">
                <el-row :gutter="20">
                    <el-col :span="24" v-if="userData.bankCardPicture">
                        <el-form-item label="银行卡（卡号面）："
                                      prop="name">
                            <el-image style="width: 100px"
                                      :src="commonJs.showFileImg(userData.bankCardPicture)"
                                      v-if="userData.bankCardPicture"
                                      :fits="fits"
                                      :preview-src-list="[commonJs.showFileImg(userData.bankCardPicture)]">
                            </el-image>
                            <span v-else>未上传</span>
                        </el-form-item>
                    </el-col>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="银行卡号：">
                                {{userData.bankAccount || ''}}
                            </el-form-item>
                        </el-col>

                        <el-col :span="16">
                            <el-form-item label="开户行：">
                                {{userData.openAccount || ''}}
                            </el-form-item>
                        </el-col>

                    </el-row>

                </el-row>

            </el-form>
        </el-card>
    </div>

</template>

<script>
// import baseinfoManage from "@/api/baseinfoManage.js";

export default {
    name: "bankCardInfo",
    props: {
        //下钻数据传输
        userData: {},
    },

    components: {},

    data() {
        return {
            itemForm: {},
            url:
                "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
            srcList: [
                "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
            ],
            fits: "contain",

            itemFormRules: {
                name: [
                    {
                        required: true,
                        message: "",
                    },
                ],
            },
        };
    },

    created() {},

    mounted() {},

    methods: {},

    computed: {},

    watch: {},
};
</script>

<style lang='scss' scoped>
.baseBg {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 7px;
}
</style>
