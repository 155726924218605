
// 员工基础信息
<template>
    <div class="itemInfo">
        <el-card shadow="always">
            <el-form :model="itemForm"
                     ref="itemForm"
                     :rules="itemFormRules"
                     label-width="130px"
                     label-position="left"
                     :inline="false">
                <el-row :gutter="20">

                    <el-row :class="[index === userData.length - 1 ? '' : 'itemLIne']"
                            v-for="(list,index) in userData"
                            :key="index">
                        <el-col :span="8">
                            <el-form-item label="紧急联系人姓名：">
                                {{list.contactName || ''}}
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item label="联系人关系：">
                                 {{list.relationShip || ''}}
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item label="联系人电话：">
                                 {{list.tel || ''}}
                            </el-form-item>
                        </el-col>

                    </el-row>

                </el-row>

            </el-form>
        </el-card>
    </div>

</template>

<script>
// import baseinfoManage from "@/api/baseinfoManage.js";

export default {
    name: "emergencyContactInfo",
    props: {
        //下钻数据传输
        userData: {
            default(){
                return []
            }
        },
    },

    components: {},

    data() {
        return {
            itemForm: {},
            fits: "",

            itemFormRules: {
                name: [
                    {
                        required: true,
                        message: "",
                    },
                ],
            },
        };
    },

    created() {},

    mounted() {},

    methods: {},

    computed: {},

    watch: {},
};
</script>

<style lang='scss' scoped>
.baseBg {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 7px;
}
</style>
