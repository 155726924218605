
// 员工基础信息
<template>
    <div class="itemInfo">
        <el-card shadow="always">
            <!-- <div slot="header">
                    <span>合同信息</span>
                </div> -->
            <el-form :model="itemForm"
                     ref="itemForm"
                     label-width="90px"
                     label-position="left"
                     :inline="false">
                <el-row :gutter="20">
                    <el-row class="itemLIne">
                        <el-col :span="8">
                            <el-form-item label="姓名："
                                          prop="name">
                                {{userData.idCardName || ''}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="工号：">
                                {{userData.workNum || ''}}
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item label="手机号："
                                          prop="name">
                                {{userData.tel || ''}}
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row class="itemLIne">
                        <el-col :span="8">
                            <el-form-item label="部门："
                                          prop="name">
                                {{userData.dingTalkDept || ''}}
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item label="职位："
                                          prop="name">
                                {{userData.job || ''}}
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item label="岗位职级：">
                                {{userData.positionLevel || ''}}
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="itemLIne">
                        <el-col :span="8">
                            <el-form-item label="员工类型："
                                          prop="name">
                                <span v-if="userData.workerType">{{userData.workerType.text || ''}}</span>
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item label="员工状态："
                                          prop="name">
                                <span v-if="userData.workerStatus">{{userData.workerStatus.text || ''}}</span>
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item label="入职时间："
                                          prop="name">
                                {{userData.entryDate || ''}}
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="itemLIne">
                        <el-col :span="8">
                            <el-form-item label="司龄：">

                                <template slot="label">
                                    司龄
                                    <el-tooltip class="item"
                                                effect="dark"
                                                content="系统根据【入职时间】自动计算"
                                                placement="top">
                                        <i class="el-icon-question"
                                           style="font-size:16px"></i>
                                    </el-tooltip>
                                </template>
                                <!-- {{userData.entryDate || ''}} -->
                                <!-- API无 -->
                                {{this.getSiLing}}

                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item label="试用期："
                                          prop="name">
                                {{userData.probationPeriod || ''}}
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item label="转正日期：">
                                {{userData.turnForwardDate || ''}}
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="itemLIne">
                        <el-col :span="8">
                            <el-form-item label="邮箱：">
                                {{userData.email || ''}}
                            </el-form-item>
                        </el-col>

                        <el-col :span="16">
                            <el-form-item label="备注：">
                                {{userData.remark || ''}}
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row class="">
                        <el-col :span="8" v-if="userData.empPhoto">
                            <el-form-item label="员工照片(一寸白底工装照)：">
                                <el-image style="width: 100px"
                                          :src="commonJs.showFileImg(userData.empPhoto)"
                                          v-if="userData.empPhoto"
                                          :fits="fits"
                                          :preview-src-list="[commonJs.showFileImg(userData.empPhoto)]">
                                </el-image>
                                <span v-else>未上传</span>
                            </el-form-item>
                        </el-col>

                        <el-col :span="8"  v-if="userData.leaveProve">
                            <el-form-item label="前公司离职证明：">
                                <el-image style="width: 100px"
                                          v-if="userData.leaveProve"
                                          :src="commonJs.showFileImg(userData.leaveProve)"
                                          :fits="fits"
                                          :preview-src-list="[commonJs.showFileImg(userData.leaveProve)]">
                                </el-image>
                                <span v-else>未上传</span>
                            </el-form-item>
                        </el-col>

                        <el-col :span="8" v-if="userData.skillCertificate">
                            <el-form-item label="技能证书：">
                                <el-image style="width: 100px"
                                          v-if="userData.skillCertificate"
                                          :src="commonJs.showFileImg(userData.skillCertificate)"
                                          :fits="fits"
                                          :preview-src-list="[commonJs.showFileImg(userData.skillCertificate)]">
                                </el-image>
                                <span v-else>未上传</span>
                            </el-form-item>
                        </el-col>
                    </el-row>

                </el-row>

            </el-form>
        </el-card>
    </div>

</template>

<script>
// import baseinfoManage from "@/api/baseinfoManage.js";

export default {
    name: "baseInfo",
    props: {
        //下钻数据传输
        userData: {
            default() {
                return {
                    workerType: { text: "" },
                    workerStatus: { text: "" },
                };
            },
        },
        userType:{},//员工状态
    },

    components: {},

    data() {
        return {
            itemForm: {},
            fits: "",
        };
    },

    created() {},

    mounted() {},

    methods: {},

    computed: {
        //司龄
        getSiLing() {
            return this.userType === 'level' ? this.commonJs.getDiffDate(
                this.userData.leaveDate,
                this.userData.entryDate
            ) : this.commonJs.getDiffDate(
                this.$moment().format("YYYY-MM-DD"),
                this.userData.entryDate
            );
        },
    },

    watch: {},
};
</script>

<style lang='scss' scoped>
.baseBg {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 7px;
}
</style>
