
// 员工基础信息
<template>
    <div class="itemInfo">
        <el-card shadow="always">
            <el-form :model="itemForm"
                     ref="itemForm"
                     :rules="itemFormRules"
                     label-width="130px"
                     label-position="left"
                     :inline="false">
                <el-row :gutter="20" :class="[index === userData.length - 1 ? '' : 'itemLIne']"
                        v-for="(list,index) in userData"
                        :key="index">
                    <el-col :span="8">
                        <el-form-item label="合同公司：">
                            {{list.contractCompany || ''}}
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="合同类型：">
                            {{list.contractType || ''}}
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="首次合同起始日：">
                            {{list.firstContractBeginDate || ''}}
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="首次合同到期日：">
                            {{list.firstContractEndDate || ''}}
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="现合同起始日：">
                            {{list.nowContractBeginDate || ''}}
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="现合同到期日：">
                            {{list.nowContractEndDate || ''}}
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="合同期限(月)：">
                             {{list.jointDeadline || ''}}
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="续签次数：">
                             {{list.renewNum || ''}}
                        </el-form-item>
                    </el-col>

                </el-row>

            </el-form>
        </el-card>
    </div>

</template>

<script>
// import baseinfoManage from "@/api/baseinfoManage.js";

export default {
    name: "contractInfo",
    props: {
        //下钻数据传输
        userData: {},
    },

    components: {},

    data() {
        return {
            itemForm: {},
            url:
                "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
            srcList: [
                "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
            ],
            fits: "contain",

            itemFormRules: {
                name: [
                    {
                        required: true,
                        message: "",
                    },
                ],
            },
        };
    },

    created() {},

    mounted() {},

    methods: {},

    computed: {},

    watch: {},
};
</script>

<style lang='scss' scoped>
.baseBg {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 7px;
}
</style>
