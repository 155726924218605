
// 员工基础信息
<template>
    <div class="itemInfo">
        <el-card shadow="always">
            <el-timeline>
                <el-timeline-item v-for="(list,index) in userData"
                                  :key="index"
                                  :timestamp="list.recordDate || list.createTime"
                                  placement="top">
                    <el-card v-if="list.node">
                        <h3><span>{{list.node.text || ""}}</span></h3>

                        <p v-if="list.node.name === 'MOBILIZE'"> 部门：{{list.deptName || ''}} </p>
                        <p v-if="list.node.name === 'MOBILIZE'">职位：{{list.job || ''}}</p>

                        <p v-if="list.node.name === 'LEAVING'">离职原因类型：{{list.separateType || ''}}</p>
                        <p v-if="list.node.name === 'LEAVING'">离职交接人：{{list.separatePerson || ''}}</p>
                        <p v-if="list.node.name === 'LEAVING'">离职原因备注：{{list.separateRemark || ''}}</p>
                    </el-card>
                </el-timeline-item>
            </el-timeline>
        </el-card>
    </div>

</template>

<script>
// import baseinfoManage from "@/api/baseinfoManage.js";

export default {
    name: "growthRecordInfo",
    props: {
        //下钻数据传输
        userData: {
            default() {
                return [];
            },
        },
    },

    components: {},

    data() {
        return {
            itemForm: {},
            fits: "",

            itemFormRules: {
                name: [
                    {
                        required: true,
                        message: "",
                    },
                ],
            },
        };
    },

    created() {},

    mounted() {},

    methods: {},

    computed: {},

    watch: {},
};
</script>

<style lang='scss' scoped>
.baseBg {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 7px;
}
</style>
