// 在职员工管理
<template>
    <div class="baseBg reserveProject">
        <div style="padding:20px 20px 5px;background-color:#fff;border-raidus:0px">
            <backUp></backUp>
        </div>
        <div class="listBox mainWhiteBg"
             style="position: relative;padding-top:5px">
            <div class="userInfoBox">
                <!-- <img class="userImg"
                     src="/img/user-avatar.920dd35c.jpg"
                     alt="">
                <div class="userName">李四</div>
                <el-tag class="tag"
                        type="success">在职</el-tag>
                <div class="userPosition">项目经理</div> -->
                 <comUserInfo :userId="userId"></comUserInfo>
            </div>
            <el-tabs v-model="activeName">
                <el-tab-pane :name="item.com"
                             v-for="(item,index) in tabList"
                             :key="index">
                    <div class="tabsLabel"
                         slot="label">
                        <el-badge :type="activeName == item.com ? 'primary' : 'warning'"
                                  class="item">
                            {{item.title}}
                        </el-badge>
                    </div>
                    <component style="margin-top:10px;"
                               v-loading="isloading"
                               :userData="mergeMore(item.type)"
                               :userExpand="userInfo.userExpand"
                               :userType="userType"
                               v-bind:is="item.com"></component>
                </el-tab-pane>
            </el-tabs>
        </div>

    </div>

</template>

<script>
// import baseinfoManage from "@/api/baseinfoManage.js";
import baseInfo from "../components/baseInfo";
import userInfo from "../components/userInfo";
import schoolInfo from "../components/schoolInfo";
import bankCardInfo from "../components/bankCardInfo";
import contractInfo from "../components/contractInfo";
import fanilyInfo from "../components/fanilyInfo";
import emergencyContactInfo from "../components/emergencyContactInfo";
import growthRecordInfo from "../components/growthRecordInfo";
import comUserInfo from "../../compensationManagement/compensation/componets/comUserInfo"; //个人信息 头像 职位那些

import backUp from "@/components/backUp";

import humanResourcesManageApi from "@/api/humanResourcesManageApi.js";
export default {
    name: "staffDetailContainer",
    props: [],

    components: {
        backUp,
        baseInfo,
        userInfo,
        schoolInfo,
        bankCardInfo,
        contractInfo,
        fanilyInfo,
        emergencyContactInfo,
        growthRecordInfo,
        comUserInfo
    },

    data() {
        return {
            activeName: "baseInfo",
            isloading: false,
            userType:"onJob",//员工状态  在职 离职
            tabList: [
                {
                    type: "userExpand,user",
                    com:"baseInfo",
                    title: "基本信息",
                },
                {
                    type: "userExpand,user",
                    com:"userInfo",
                    title: "个人信息",
                },
                {
                    type: "userDegrees",
                    com:"schoolInfo",
                    title: "学历信息",
                },
                {
                    type: "userExpand",
                    com:"bankCardInfo",
                    title: "银行卡信息",
                },
                {
                    type: "userContracts",
                    com:"contractInfo",
                    title: "合同信息",
                },
                {
                    type: "userFamilyInfos",
                    com:"fanilyInfo",
                    title: "家庭信息",
                },
                {
                    type: "userContacts",
                    com:"emergencyContactInfo",
                    title: "紧急联系人",
                },
                {
                    type: "userGrowthRecordVos",
                    com:"growthRecordInfo",
                    title: "成长记录",
                },
            ], //员工状态tab
            userInfo: {
                user: {workerStatus:{text:123}}, //用户信息
                userExpand: {}, //	员工信息
                userContacts: [{}], //	紧急联系人
                userContracts: [{},{}], //	合同信息
                userDegrees: [{}], //	学历信息
                userFamilyInfos: [{},{}], //	家庭信息
                userGrowthRecordVos: [{}], //		成长记录
            },
            userId:""
        };
    },

    created() {},

    mounted() {
        this.userId = JSON.parse(this.$route.query.data).userId;
        this.userType = JSON.parse(this.$route.query.data).userType;
        console.log(this.userType)
        this.getList();
    },

    methods: {
        /* 获取详情 */
        getList() {
            this.isloading = true;
            humanResourcesManageApi
                .findUserView(JSON.parse(this.$route.query.data).userId)
                .then((res) => {
                    this.isloading = false;
                    this.userInfo = res.content;
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        //合并多项信息
        mergeMore(types){
            let type = types.split(','),data = {};
            type.forEach(v => {
                type.length === 1 ? data = this.userInfo[v] : Object.assign(data,this.userInfo[v])
            });
            return data;
        }
    },

    computed: {},

    watch: {},
};
</script>

<style lang='scss' scoped>
.reserveProject ::v-deep .el-tabs__nav-scroll {
    overflow: visible;
}
.reserveProject ::v-deep .el-tabs__nav-wrap {
    overflow: visible;
    height: 40px;
}

.userInfoBox {
    display: inline-block;
    width: 100%;
    position: relative;
    height: 90px;
}
.userInfoBox .userImg {
    border-radius: 50%;
    border: 1px solid #fff;
    position: absolute;
    left: 15px;
    top: 15px;
    width: 60px;
}

.userInfoBox .userName {
    position: absolute;
    left: 90px;
    top: 20px;
    font-size: 18px;
    font-family: "微软雅黑";
}

.userInfoBox .userPosition {
    position: absolute;
    left: 90px;
    top: 55px;
    font-size: 14px;
    font-family: "微软雅黑";
}

.userInfoBox .tag {
    position: absolute;
    left: 150px;
    top: 15px;
}
.mainWhiteBg .el-tabs__header {
    margin-bottom: 30px;
}
</style>
