
// 员工基础信息
<template>
    <!-- <div class="itemInfo"> -->
    <el-card shadow="always">
        <!-- <div slot="header">
                    <span>合同信息</span>
                </div> -->
        <el-form :model="itemForm"
                 ref="itemForm"
                 :rules="itemFormRules"
                 label-width="130px"
                  label-position="left"
                 :inline="false">
            <el-row :gutter="20">
                <el-row class="itemLIne">
                    <el-col :span="8" v-if="userData.idCardPositive">
                        <el-form-item label="身份证（头像面）："
                                      prop="name">
                            <el-image style="width: 100px"
                                      v-if="userData.idCardPositive"
                                      :src="commonJs.showFileImg(userData.idCardPositive)"
                                      :fits="fits"
                                      :preview-src-list="[commonJs.showFileImg(userData.idCardPositive)]">
                            </el-image>
                            <span v-else>未上传</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16"  v-if="userData.idCardOpposite">
                        <el-form-item label="身份证（国徽面）："
                                      prop="name">
                            <el-image style="width: 100px"
                                      v-if="userData.idCardOpposite"
                                      :src="commonJs.showFileImg(userData.idCardOpposite)"
                                      :fits="fits"
                                      :preview-src-list="[commonJs.showFileImg(userData.idCardOpposite)]">
                            </el-image>
                            <span v-else>未上传</span>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="itemLIne">
                    <el-col :span="8">
                        <el-form-item label="身份证姓名："
                                      prop="name">
                            {{userData.idCardName || ''}}
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="证件号码："
                                      prop="name">
                            {{userData.idCard || ''}}
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="出生日期："
                                      prop="name">
                            {{userData.birthDate || ''}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="itemLIne">
                    <el-col :span="8">
                        <el-form-item label="年龄：">
                            <template slot="label">
                                年龄
                                <el-tooltip class="item"
                                            effect="dark"
                                            content="系统根据【出生日期】自动计算"
                                            placement="top">
                                    <i class="el-icon-question"
                                       style="font-size:16px"></i>
                                </el-tooltip>
                                ：
                            </template>
                            {{userData.age || ''}}
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="性别："
                                      prop="name">
                             <span v-if="userData.sex">{{userData.sex.text || ''}}</span>
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="民族："
                                      prop="name">
                              {{userData.nation || ''}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="itemLIne">
                    <el-col :span="8">
                        <el-form-item label="身份证地址：">
                             {{userData.idCardAddr || ''}}
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="证件有效期：">
                            {{userData.idCardValid || ''}}
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="婚姻状态：">
                            {{userData.maritalStatus || ''}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="itemLIne">
                    <el-col :span="8">
                        <el-form-item label="首次参加工作：">
                            {{userData.firstWorkDate || ''}}
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="工龄：">
                            <template slot="label">
                                工龄
                                <el-tooltip class="item"
                                            effect="dark"
                                            content="系统根据【首次参加工作时间】自动计算"
                                            placement="top">
                                    <i class="el-icon-question"
                                       style="font-size:16px"></i>
                                </el-tooltip>
                                ：
                            </template>
                            {{getGongLing}}
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="户籍类型：">
                             {{userData.householdType || ''}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="itemLIne">
                    <el-col :span="8">
                        <el-form-item label="住址：">
                            {{userData.address || ''}}
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="政治面貌：">
                            {{userData.politicsFace || ''}}
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="个人社保账号：">
                          {{userData.socialSecAccount || ''}}
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-col :span="8">
                    <el-form-item label="个人公积金账号：">
                          {{userData.providentFundAccount || ''}}
                    </el-form-item>
                </el-col>

            </el-row>

        </el-form>
    </el-card>
    <!-- </div> -->

</template>

<script>
// import baseinfoManage from "@/api/baseinfoManage.js";

export default {
    name: "userInfo",
    props: {
        //下钻数据传输
        userData: {sex:{text:""}},
    },

    components: {},

    data() {
        return {
            itemForm: {},
            fits: "",
            itemFormRules: {
                name: [
                    {
                        required: true,
                        message: "",
                    },
                ],
            },
        };
    },

    created() {},

    mounted() {},

    methods: {},

    computed: {
        //工龄
        getGongLing(){
            return this.commonJs.getDiffDate(this.$moment().format("YYYY-MM-DD"),this.userData.firstWorkDate);
        }
    },

    watch: {},
};
</script>

<style lang='scss' scoped>
.baseBg {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 7px;
}
</style>
